.about {
    padding: 50px 12px;
    background: #ffffff;
    overflow: hidden;
}

.about .heading {
    margin-bottom:0;
    padding-right: 105px;
}
.about .heading h4 {
    margin-bottom: 20px;
}
.about .heading h4 span{
    color: #174a69;
    font-weight: 700;
}
.about .heading h5 {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 30px;
}
.about .heading h6 {
    color: #66b47e;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
}

.about .heading p {
    color: #00000090;
    font-size: 16px;
}

.about .heading p span {
    font-weight: 600;
    color: #000000;
}

.about-img-wrap {
    position: relative;
}

.play-btn-02 {
    position: absolute;
    left: 15%;
    top: 16%;
}

.play-btn-02 .popup-video {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 15px;
    display: inline-block;
    text-align: center;
    position: relative;
    border-radius: 50%;
    background: linear-gradient(240deg, #086ad8 0%, #42b9ff 100%);
    color: #ffffff;
    transition: all 0.3s linear;
}
.play-btn-02 .popup-video:before , .play-btn-02 .popup-video::after{
    content: "";
    border: 2px solid #086ad8;
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    -webkit-animation: zoombig 3.25s linear infinite;
    animation: zoombig 3.25s linear infinite;
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
}
.play-btn-02 .popup-video::after {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.about-img img {
    border-radius: 10px;
}

.about-img.about-img-big {
    text-align: right;
}
.about-img.about-img-sm {
    position: absolute;
    left: -15px;
    bottom: 30px;
    border: 8px solid #ffffff;
    border-radius: 10px;
}
@-webkit-keyframes zoombig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 3px;
  }
  65% {
    border-width: 2px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 2px;
  }
}

@keyframes zoombig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 3px;
  }
  65% {
    border-width: 2px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 2px;
  }
}
