.contact {
    padding: 90px 12px;
    background: #f9f9f9;
}

.contact .heading {
    text-align: center;
    margin-bottom: 75px
}

.heading h5 {
    font-size: 2.5rem;
    font-weight: 700;
}

.contact-detail {
    width: 100%;
    background-color: #ffffff;
    padding: 50px 150px 50px 50px;
    border-radius: 18px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
}
.contact-detail .form-group{
    margin-bottom: 24px;
}
.contact-detail .form-group .form-control{
    padding: 15px;
    border-radius: 25px;
    font-size: 14px;
}
.contact-detail .form-group .form-control.react-select-container{
    padding: 6px;
}
.contact-detail .form-group .form-control .react-select__control{
    padding-bottom: 0;
    background: transparent;
    border: none;
}

.contact-detail .form-group label {
    margin-bottom: 10px;
    color: #00000090;
    font-weight: 500;
}

button {
    margin-top: 15px;
    padding: 15px 20px;
    background-color: #174a69;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #174a69; /* Darker shade on hover */
}

.contact button {
    width: 40%;
}
