.App{
  background-color: #f3f0ee;
}
a, a:hover{
  text-decoration: none;
}
.sep-element{
    border-bottom: 1px solid #ffffff;
    width: 100px;
    margin: auto;
    position: relative;
}
.sep-element.primary{
    border-bottom: 1px solid #174a69;
}
.sep-element:before{
    content: "";
    background-color: #2f3849;
    border: 1px solid #ffffff;
    width: 10px;
    height: 11px;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
}
