.benefit-card{
    color:#ffffff;
    border-radius: 20px;
    padding-top:15px;
}
.benefit-card h5{
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 0;
}
.benefit-card h5 span{
    font-size: 60px;
    margin-right: 5px;
    color: #ffffff90;
}
.benefit-card p{
    color: #ffffff90;
    font-weight: 300;
    font-size: 15px;
}
.pt-0-inner .benefit-card{
    padding-top: 0;
}
