.faq {
    padding: 50px 12px;
    background: #ffffff;
}

.faq .heading {
    text-align: center;
    margin-bottom: 75px;
}

.heading p {
    color: #71717a;
    font-size: 18px;
}

.faq-container {
    background-color: #ffffff;
    padding: 15px 40px;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.faq-item {
    border-bottom: 1px solid #174a6920;
    padding: 15px;
    cursor: pointer;
}

.faq-item:last-child {
    border-bottom: 0;
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-question h3, .faq-question span {
    font-size: 18px;
    font-weight: 600;
    color: #174a69;
    margin: 0;
}

.icon {
    transition: transform 0.2s ease;
}

.faq-item.open .icon {
    transform: rotate(0deg);
    font-size: 24px;
}

.faq-answer {
    padding: 15px 0;
    color: #00000090;
    font-size: 16px;
}
