.product-card {
    background: #fff;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 32%;
    margin-top: 30px;
    position: relative;
  }
  .product-card:before{
    content: '';
    position: absolute;
    right: -45px;
    bottom: -45px;
    width: 130px;
    height: 130px;
    background: #66b47e08;
    border-radius: 50%;
    z-index: 0;
  }
  .product-image {
    width: 100%;
    height: 200px; 
    overflow: hidden; 
    display: flex;
    align-items: center;
    justify-content: center;
}

  .product-image img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .product-content {
    padding: 45px 30px 15px;
    z-index: 1;
    position: relative;
  }
  
  .product-content h4 {
    font-size: 14px;
    color: #66b47e;
    margin-bottom: 10px;
  }
  
  .product-content h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .product-content .bolt-icon{
    margin-right:5px;
  }
  
  .product-content p {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
  }
