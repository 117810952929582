footer{
    background-color: #174a69;
}
.social-links{
    background-color: #66b47e;
    padding:20px 12px;
}

footer  hr{
    width: 60px;
    background-color: #ffffff10;
    height:2px;
}

.copyright{
   background-color: #0e354d;
}
.footer-bottom{
    padding-bottom: 45px;
}
.contact-icon{
    margin-right:5px;
  }
.social-links-tag{
    background: #ffffff;
    width: 35px;
    height: 35px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #66b47e;
}
.footer{
    padding: 0;
}
