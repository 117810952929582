.benefits{
    background: linear-gradient(rgba(0,38,66,0.85), rgba(0,38,66,0.85)), url("../../images/benifits-bg.jpg") no-repeat 100% fixed;
    padding:50px 20px;
    background-size: auto;
    z-index: 1;
    position: relative;
    width: 100%;
}
.benefits .heading{
    text-align: center;
    margin-bottom:75px;
}

.benefits .heading h1{
    font-weight: 600;
    font-size:40px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0;
}

.benefits .heading p{
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
}
.benefits-row{
    background: url("../../images/bg-img-benefits.jpg") center no-repeat;
    background-size: cover;
    position: relative;
}
.benefits-row{
    padding:45px 0 60px;
}
.padding-left-div{
    padding-left: 45px;
}
.padding-right-div{
    padding-right: 75px;
}
