.productsPage {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 50px;
    background-color: white;
}


/* Custom Divider with Square */
.custom-divider {
    position: relative;
    text-align: center;
}

.custom-divider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background-color: #66b47e;
    z-index: 2;
}

.custom-divider::after {
    content: '';
    position: absolute;
    top: 6px; 
    left: 35%;
    width: 30%;
    height: 2px;
    background: linear-gradient(90deg, #66b47e, #174a69);
    z-index: 1;
}


#RealProfitTracking,
#AutomatedPayment,
#MultiMarketPlace {
    padding: 80px 12px; 
}

#TallyIntegration,
#GstTax,
#AiPowered {
    padding: 80px 12px; 
}

.productsPage h1, 
.keyFeatures h1, .exampleSection h1 {
    color: #66b47e;
    font-size: 32px; 
    font-weight: 700;
    text-transform: capitalize;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

/* .productsPage h1::after {
    content: '';
    position: absolute;
    left: 8%;
    bottom: -1px;
    width: 45%;
    height: 1px;
    background: linear-gradient(90deg, #66b47e, #174a69);
    border-radius: 2px;
} */

/* .productsPage h1::before {
    content: '';
    position: absolute;
    right: 0;
    left: 30%;
    z-index: 1;
    bottom: -6px;
    width: 10px;
    height: 10px;
    background-color: #66b47e;
    transform: rotate(45deg);
} */

.productsPage h4 {
    margin: 20px 0;
    font-size: 16px; 
}

.productsPage .image-part {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inverted {
    padding: 0 20px;
}

.image-container {
    position: relative;
    width: 100%; 
    max-width: 62%; 
    display: inline-block;
    line-height: 0;
}


.image-container::before,
.image-container::after {
    content: '';
    position: absolute;
    background: transparent;
    border: 2px solid #66b47e;
}


.image-container::before {
    top: -8px;
    left: -8px;
    width: 40%;
    height: 40%;
    border-right: none;
    border-bottom: none;
}


.image-container::after {
    bottom: -8px;
    right: -8px;
    width: 40%;
    height: 40%;
    border-left: none;
    border-top: none;
}

.image-container img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.keyFeatures{
    padding: 50px 12px ;
    background-color: #ffffff;
    background-image: url(https://website-prod.cache.wpscdn.com/img/background.d16b409.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.exampleSection{
    padding: 50px 12px;
    background-color: white;
}

.keyFetaure-points-text p{
    margin-bottom: 5px;
}

.keyFetaure-points-text p svg{
    color: #66b47e;
    margin-right: 10px;
}

.keyFeature-list {
    list-style: none;
    padding: 0;
}

.keyFetaure-points-text p span{
    font-size: 18px ;
    font-weight: 500;
}
/* Responsive Styles */
@media (max-width: 992px) {
    .productsPage h1 {
        font-size: 28px; 
    }

    .productsPage h4 {
        font-size: 14px; 
    }

    .inverted {
        padding: 0 20px;  
    }

    .image-container {
        max-width: 80%; 
    }
}

@media (max-width: 768px) {
    .productsPage h1 {
        font-size: 24px; 
    }

    .productsPage h4 {
        font-size: 14px;
    }

    .inverted {
        padding: 0px 20px; 
        
    }

    .image-container {
        max-width: 60%; 
    }

   
  

    .productsPage .col-md-6 {
        width: 100%; 
    }

    .image-part {
        order: 2; 
    }

    .inverted {
        order: 1; 
    }
}