.banner-main{
    padding:140px 12px 80px;
    background-color: #f9f9f9;
    position: relative;
    overflow: hidden;
}
.banner-main:after{
    content: '';
    position: absolute;
    right: -30%;
    top: 0;
    width: 1000px;
    height: 1000px;
    background: #66b47e20;
    transform: rotate(25deg);
    z-index: 0;
}
.banner-main h1{
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 30px;
}
.banner-main span{
    color:#174a69;
}
.banner-main ul li{
    position: relative;
    padding-left: 20px;
    font-size: 18px;
    color: #71717a;
    line-height: 36px;
}
.banner-main ul li:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #174a69;
    margin: auto;
}
.banner-main ul li:after{
    content: '';
    position: absolute;
    left: -2px;
    top: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #66b47e30;
    margin: auto;
}
@keyframes shake{
    0% {
        transform: translateX(0); 
    }
    25% {
        transform: translateX(-30px); 
    }
    50% {
        transform: translateX(30px);
    }
    75% {
        transform: translateX(-30px); 
    }
    100% {
        transform: translateX(0); 
    }
  }

  

.image-section img{
    /* animation: shake 3s ease-in-out infinite; */
    position: relative;
    z-index: 1;
}

.banner-main button{
    background-color: #174a69;
    color: #ffffff;
    padding: 14px 40px;
    border-radius: 12px;
    margin-top: 30px;
    box-shadow: 0 4px 10px #174a6950;
    border: 2px solid #174a69;
    font-size: 20px;
}
.banner-main button:hover{
    background:#174a69;
}
