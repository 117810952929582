.integration{
    padding:50px 12px;
    background: #f9f9f9;
    overflow: hidden;
}

/* .integration .heading{
    text-align: right;
    padding-bottom: 0;
    margin-bottom: 0;
}

.integration h5{
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom:30px;
}
.integration h6{
    color: #66b47e;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
}

.integration .heading p{
    color: #71717a;
    font-size: 16px;
} */

.integration  .heading {
    text-align: center;
    margin-bottom: 75px;
  }
  .integration .heading h6 {
    color: #66b47e;
    font-weight: 700;
    line-height: 40px;
    font-size: 24px;
    margin-bottom: 0;
  }

.logo-div{
    padding: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-div.div-1, .logo-div.div-2{
    border-bottom: 1px dashed #d1d1d1;
}

.logo-div.div-1, .logo-div.div-3{
    border-right: 1px dashed #d1d1d1;
}

@media (max-width: 768px) and (min-width:320px) {
    .integration .case-studio .case-studio-tabs{
        justify-content: space-between;
    }
    .integration .case-studio .case-studio-tabs .nav-item{
        width: 45% !important;

    }

    .integration .case-studio .case-studio-tabs .nav-item button{
        margin-bottom: 10px;
    }


}




