.products{
    padding:50px 12px;
    background-color: #f9f9f9;
}

.products h5{
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.product-heading{
    margin-bottom: 20px;
}
.products p{
    color: #00000090;
}
.productsList{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px
}
