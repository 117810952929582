.testimonials{
    padding:50px 12px;
    position: relative;
    background: #fff;
}
.testimonials .heading{
    text-align: center;
    margin-bottom: 45px;
}

.testimonials .heading h5{
    font-size: 40px;
    font-weight: 700;
}
.testimonials .heading p{
    color: #00000090;
}
.testimonials-card{
    background: #ffffff;
    border-radius:0;
    padding: 45px 30px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.1) 0 4px 6px 0;
}
.testimonials-card:before{
    content: "";
    border: 20px solid transparent;
    display: block;
    position: absolute;
    top: 100%;
    right: 30px;
    margin-top: -20px;
    border-left-color: #fff;
}

.carousel-caption {
    position: initial;
    padding: 2rem 2rem 5rem;
    color: #000000;
    text-align: center;
}
.carousel-caption p{
    color: #00000090;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 0;
}
.carousel-caption h5{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}
.carousel-caption h5 span{
    color: #174a69;
    font-weight: 500;
    font-size: 12px;
    font-style: italic;
}
.carousel-caption h6{
    font-size: 14px;
    margin-bottom: 0;
}
.testimonials .carousel-control-prev, .testimonials .carousel-control-next{
    width: 50px;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #174a69;
    color: #ffffff;
    border-radius: 50px;
}
.testimonials .carousel-control-prev{
    left: -45px;
}
.testimonials .carousel-control-next{
    right: -45px;
}
#image-caption h4{
    font-weight: 700;
    font-size:20px;
}
.testimonials-client-details{
    display: flex;
    align-items: center;
    width: 50%;
    float: right;
    margin-top: 30px;
}
.client-img{
    width: 55px;
    height: 55px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    float: left;
}
.client-img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.client-details-inner{
    float: left;
    text-align: left;
    width: calc(100% - 55px);
    padding-left: 20px;
}
